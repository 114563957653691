/* .stackChart svg {
  display: block;
  width: 100%;
  height: 300px;
  background: #fff;
  overflow: visible;
} */

.heightNormal {
  display: block;
  width: 100%;
  height: 300px;
  background: #fff;
  overflow: visible;
}
.heightZoom {
  display: block;
  width: 100% !important;
  height: 100%;
  background: #fff;
  overflow: visible;
}
.stackChart {
  position: relative;
}

.stackChart svg rect.selection {
  stroke: none;
}

.stackBarField input {
  margin-right: 0.5em;
}

.stackBarField {
  display: inline-block;
  margin: 0 12px;
}
.layer text{
font-size: 13px;
}
/* tooltip */
.stackChart .tooltipstackbar {
  background: #ffff;
  box-shadow: 0 0 5px #999999;
  color: #333;
  display: none;
  font-size: 13px;
  padding: 20px;
  position: absolute;
  text-align: center;
  min-width: 275px;
  height: auto;
  z-index: 9999;
}

.nonBlr{
color:"#000"
}
.blr{
color:gray
}

.colorLegend{
  width: 19px;
  height: 11px;
}

.tick{
  width:auto;
}
.axisgrey line{
  stroke: #D3D3D3;
}

.axisgrey path{
  stroke: #D3D3D3;
}
.axisgrey text{
  font-size: 12px;
}

/*Legend styles*/
.stackBarField label.legendContainer:hover > span{
  opacity: 0.5;
}
.stackBarField label.legendContainer:hover > span:hover{
  opacity: 1 !important;
}