/* tooltip */
.tooltip {
    background: #ffff;
    box-shadow: 0 0 5px #999999;
    color: #333;
    display: none;
    font-size: 13px;
    left: 130px;
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 95px;
    width: auto;
    z-index: 10;
  }
  .selecttot {
    position: relative;
}
  
  .axisgrey line{
    stroke: #D3D3D3
  }
  
  .axisgrey path{
    stroke: #D3D3D3
  }