/* tooltip */
.tooltip {
    background: #ffff;
    box-shadow: 0 0 5px #999999;
    white-space: nowrap;
    color: #333;
    display: none;
    font-size: 14px;
    left: 130px;
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 95px;
    width: auto;
    z-index: 10;
  }
  .donutchart {
    position: relative;
  }