.turnOverChart {
    position: relative;
}

.tool-tip {
    position: absolute;
    z-index: 1;
    /* opacity: 1!important; */
    font-size: 12px;
    background: #fff;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.axis{
    font-size: 12px;
    color: #b3b3b3;
    font-family: 'Roboto';
    line-height: 1.6;
    letter-spacing: 0.0075em;
    font-weight: 400;
}

.chart-legend-item {
    border-left: 3px solid #fff;
    padding-left: 12px;
    margin-top: 12px;
}
.chart-legend-item.exit {
    border-color: #ffb457;
}
.chart-legend-item.others {
    border-color: #54c0fd;
}