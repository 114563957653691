/*** Geneneral CSS ***/
html,
body {
  height: 100%;
}
#root {
  height: 100%;
  background: #f2f4f5;
}
body {
  background: #f2f4f5;
  font-family: "Roboto";
  color: #292929;
}
a:hover,
a {
  text-decoration: none !important;
}
/* .txtunderline a{
  text-decoration: underline !important;
} */
.rotate180 {
  transform: rotate(-180deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  -webkit-transform: rotate(-180deg);

  /* Firefox */
  -moz-transform: rotate(-180deg);

  /* IE */
  -ms-transform: rotate(-180deg);

  /* Opera */
  -o-transform: rotate(-180deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  text-align: center;
  white-space: nowrap;
  margin: 0px; /* virtually reduce space needed on width to very little */
  display: inline-block;
  vertical-align: text-bottom;
}
.rotate {
  transform: rotate(-180deg);

  /* Legacy vendor prefixes that you probably don't need... */

  /* Safari */
  /* -webkit-transform: rotate(-90deg); */

  /* Firefox */
  /* -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  text-align: center;
  white-space: nowrap;
  /* margin: 0px -100px; */
  /* display: inline-block; */
  /* vertical-align: text-bottom; */
  writing-mode: vertical-rl;
  position: relative;
  bottom: 0;
  left: 0;

}
.rotate:before {
  content: "";
  width: 0;
  /* padding-top: 110%; */
  /* takes width as reference, + 10% for faking some extra padding */
  display: inline-block;
  vertical-align: middle;
}
.infoToolTip > .MuiTypography-h6 {
  color: "#fff" !important;
}
.width100 {
  width: 100%;
}
.width200 {
  width: 200%;
}
.width90 {
  width: 90%;
}
.width80 {
  width: 80%;
}
.width60 {
  width: 80%;
}
.width20 {
  width: 20%;
}
.width60 {
  width: 60%;
}
.height100 {
  height: 100%;
}
.height29 {
  height: 29px;
}
.hauto {
  height: auto !important;
}
.width70 {
  width: 70%;
}
.display-block {
  display: block;
}
.scrolly {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollx {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollIntial {
  overflow: initial !important;
}
.breakWord {
  word-break: break-all;
}
.nowrap {
  white-space: nowrap;
}
.flexnowrap {
  flex-wrap: nowrap !important;
}
.marginAuto {
  margin: auto !important;
}
.marginLAuto {
  margin-left: auto;
}
.relative {
  position: relative;
}
.bgnone {
  background: none !important;
}
.bgwhite {
  background-color: #fff;
}
.bgGrey {
  background-color: #f4f6f7;
}
.bgGreyStrict {
  background-color: #dde2e4 !important;
}
.boxShadow {
  border: 0.1px solid #00000017;
}
.fontSize9 {
  font-size: 9px !important;
}
.fontSize10 {
  font-size: 10px !important;
}
.fontSize12 {
  font-size: 12px !important;
}
.fontSize13 {
  font-size: 13px !important;
}
.fontSize14 {
  font-size: 14px !important;
}
.fontSize15 {
  font-size: 15px !important;
}
.paddingLeft15 {
padding-left: 15px !important;
}
.fontSize16 {
  font-size: 16px !important;
}
.fontSize18 {
  font-size: 18px !important;
}
.fontSize20 {
  font-size: 20px !important;
}
.fontSize22 {
  font-size: 22px !important;
}
.fontSize24 {
  font-size: 24px !important;
}
.fontSize26 {
  font-size: 26px !important;
}
.fontSize30 {
  font-size: 30px;
}
.fontSize60 {
  font-size: 60px !important;
  font-weight: bolder;
}
.fontSize64 {
  font-size: 64px !important;
  font-weight: bold;
}
.fontWeight600 {
  font-weight: 600;
}
.fontWeightBold {
  font-weight: bold;
}
.fontWeightBoldImp {
  font-weight: bold !important;
}
.font-italic{
  font-style: italic;
}
.height70 {
  margin-top: 70px;
}
.buttonOrange-hover:hover {
  background: #ca914c !important;
}
.buttonOrange {
  background: #ffa12d !important;
}
.colorOrange {
  color: #ffa12d !important;
}
.colorGrey {
  color: #c0c1c2 !important;
}
.colorBlack {
  color: black !important;
}
.colorGreen {
  color: #5b9621 !important;
}
.colorTheme {
  color: #54c0fd !important;
}
.w100 {
  width: 100% !important;
}
.dflex {
  display: flex !important;
  white-space: nowrap;
}
.dflexOnly {
  display: flex !important;
}
.dflexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spacearound {
  justify-content: space-around;
}
.spacebetween {
  justify-content: space-between !important;
}
.justifycenter {
  justify-content: center !important;
}
.justifystart {
  justify-content: start !important;
}
.justifyend {
  justify-content: flex-end !important;
}
.flexgrow {
  flex-grow: 1;
}
.flexBasis33 {
  flex-basis: 33.33%;
}
.flexdirection {
  flex-direction: column;
}
.lineHeight20 {
  line-height: 20px;
}
.lineHeight30 {
  line-height: 30px;
}
.lineHeight18 {
  line-height: 18px;
}
.txtwhite {
  color: #fff !important;
}
.txtgrey {
  color: #888383 !important;
}
.txtright {
  color :#888383 !important;
  float: right;
  text-align: right;
}
.txtcenter {
  text-align: center !important;
}
.txtLeft {
  text-align: left !important;
}
.txtEnd {
  text-align: end !important;
}
.selfcenter {
  align-self: center !important;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.cursor-not_allowed {
  cursor: not-allowed !important;
}
.icon-hover:hover {
  filter: invert(59%) sepia(77%) saturate(524%) hue-rotate(175deg);
}
.txtRegular {
  font-weight: 400 !important;
}
.txtMedium {
  font-weight: 500 !important;
}
.txtSemiBold {
  font-weight: 600 !important;
}
.txtBold {
  font-weight: 600 !important;
}
.border-secondary {
  border: #ebeced solid;
}
.borderRadius6 {
  border-radius: 6px;
}
.borderRadius30 {
  border-radius: 30px;
}
.borderRadius15{
  border-radius: 15px;
}
.centercontainer,
.container {
  margin: auto;
  padding: 20px;
  max-width: 1140px;
  position: relative;
  width: 100%;
}
.containerTop0 {
  margin: auto;
  padding: 0px 20px 20px 20px;
  max-width: 1140px;
  position: relative;
  width: 100%;
}
.opacity9 {
  opacity: 0.9;
}
.opacity5 {
  opacity: 0.5;
}
.opacity0 {
  opacity: 0;
}
.border {
  border: 1px solid #f4f6f7;
}
.borderBottom {
  border-bottom: 1px solid #f4f6f7;
}
.ImgActiveOrange img,
.icon-hover.active {
  filter: invert(59%) sepia(65%) saturate(431%) hue-rotate(-3deg)
    brightness(112%) contrast(225%) !important;
}
.emailorange {
  background: #ffa12d !important;
}
.avatarwhite.MuiAvatar-root {
  background: #fff !important;
  width: 15px;
  height: 15px;
}
.textWrap {
  white-space: pre-line;
}
.displayNone .MuiAutocomplete-endAdornment {
  display: none;
}
.actionblue {
  color: #54c0fd;
}
.highlighted {
  background: yellow;
}
.smsCharLimit {
  position: absolute;
  right: 5px;
  bottom: 5px;
  color: #c0c1c2 !important;
  font-size: 13px;
}

/*** Margin & Padding classes  ***/
.p-0 {
  padding: 0px !important;
}
.p3 {
  padding: 3px !important;
}
.p5 {
  padding: 5px !important;
}
.p6 {
  padding: 6px !important;
}
.p10 {
  padding: 10px !important;
}
.p-1 {
  padding: 10px;
}
.p12 {
  padding: 12px;
}
.p15 {
  padding: 15px;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px;
}
.p-4 {
  padding: 40px;
}
.p-5 {
  padding: 50px;
}
.p-15 {
  padding: 15px;
}
.p-40 {
  padding: 40px;
}
.pt-0 {
  padding-top: 0px !important;
}
.pt2 {
  padding-top: 2px !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pt4 {
  padding-top: 4px !important;
}
.pt-1 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-2 {
  padding-top: 20px !important;
}
.pt-3 {
  padding-top: 30px !important;
}
.pt-4 {
  padding-top: 40px !important;
}
.pt-5 {
  padding-top: 50px !important;
}
.pt-6 {
  padding-top: 60px !important;
}
.pt12 {
  padding-top: 12px;
}
.pt-13 {
  padding-top: 13px;
}
.pt-24 {
  padding-top: 24px;
}
.pt-87 {
  padding-top: 87px;
}
.pt-35 {
  padding-top: 35px;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb4 {
  padding-bottom: 4px !important;
}
.pb5 {
  padding-bottom: 5px !important;
}
.pb-1 {
  padding-bottom: 25px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-2 {
  padding-bottom: 20px !important;
}
.pb-3 {
  padding-bottom: 30px !important;
}
.pb-4 {
  padding-bottom: 40px !important;
}
.pl4 {
  padding-left: 4px;
}
.pl5 {
  padding-left: 5px;
}
.pl-0 {
  padding-left: 0px;
}
.pl-1 {
  padding-left: 10px !important;
}
.pl-14 {
  padding-left: 14px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-2 {
  padding-left: 20px !important;
}
.pl-3 {
  padding-left: 30px !important;
}
.pl-4 {
  padding-left: 40px !important;
}
.pl-5 {
  padding-left: 50px !important;
}
.pl-55 {
  padding-left: 55px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pr2 {
  padding-right: 2px;
}
.pr4 {
  padding-right: 4px;
}
.pr5 {
  padding-right: 5px;
}
.pr-1 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-2 {
  padding-right: 20px !important;
}
.pr-3 {
  padding-right: 30px !important;
}
.pr-4 {
  padding-right: 40px !important;
}
.pr-5 {
  padding-right: 50px !important;
}
.ps-1 {
  padding: 0px 10px;
}
.m-0 {
  margin: 0px !important;
}
.m-1 {
  margin: 10px;
}
.m-15 {
  margin: 15px;
}
.m-2 {
  margin: 20px;
}
.m-3 {
  margin: 30px;
}
.m-4 {
  margin: 40px;
}
.m-5 {
  margin: 50px;
}
.mt0 {
  margin-top: 0px !important;
}
.mt2 {
  margin-top: 2px !important;
}
.mt3 {
  margin-top: 3px !important;
}
.mt4 {
  margin-top: 4px !important;
}
.mt5 {
  margin-top: 5px;
}
.mt7 {
  margin-top: 7px;
}
.mt8 {
  margin-top: 8px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-1 {
  margin-top: 10px !important;
}
.mt15 {
  margin-top: 15px;
}
.mt-2 {
  margin-top: 20px !important;
}
.mt-3 {
  margin-top: 30px !important;
}
.mt-4 {
  margin-top: 40px !important;
}
.mt-5 {
  margin-top: 50px !important;
}
.mt-6 {
  margin-top: 60px !important;
}
.mt-7 {
  margin-top: 70px !important;
}
.mt-8 {
  margin-top: 80px !important;
}
.mt-9 {
  margin-top: 90px !important;
}
.mt-10 {
  margin-top: 100px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.mb4 {
  margin-bottom: 4px !important;
}
.mb8 {
  margin-bottom: 8px !important;
}
.mb-1 {
  margin-bottom: 10px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-2 {
  margin-bottom: 20px !important;
}
.mb-3 {
  margin-bottom: 30px !important;
}
.mb-4 {
  margin-bottom: 40px !important;
}
.mb-5 {
  margin-bottom: 50px !important;
}
.mb-6 {
  margin-bottom: 60px !important;
}
.mb-7 {
  margin-bottom: 70px !important;
}
.mb-8 {
  margin-bottom: 80px !important;
}
.mb8 {
  margin-bottom: 8px !important;
}
.mb-m5 {
  margin-bottom: -5px !important;
}
.mb-m7 {
  margin-bottom: -7px !important;
}
.mb-m4 {
  margin-bottom: -4px !important;
}
.mb-14 {
  margin-bottom: 14px !important;
}
.ml-auto {
  margin-left: auto !important;
}
.ml5 {
  margin-left: 5px !important;
}
.ml-1 {
  margin-left: 10px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.ml-2 {
  margin-left: 20px !important;
}
.ml-3 {
  margin-left: 30px !important;
}
.ml-4 {
  margin-left: 40px !important;
}
.ml-15 {
  margin-left: 15px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mr1 {
  margin-right: 1px !important;
}
.mr-1 {
  margin-right: 10px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mr-2 {
  margin-right: 20px !important;
}
.mr-3 {
  margin-right: 30px !important;
}
.mr-4 {
  margin-right: 40px !important;
}
.height-90 {
  height: 90px;
}

/** css dot **/
.dotyesnochart {
  height: 12px;
  width: 12px;
  background-color: #c0c1c2;
  border-radius: 50%;
  display: inline-block;
}

/*** Loader ***/
.btnLoader {
  display: none !important;
  color: #ffffff !important;
  margin-right: 10px !important;
}

/* Date Picker */
.cursor-pointer .MuiOutlinedInput-inputAdornedEnd {
  cursor: pointer;
}

/*** Border Radius  ***/
.br-0 .MuiOutlinedInput-root {
  border-radius: 0px;
}
.boderRadius2 {
  border-radius: 2px;
}
.boderRadius2 .MuiOutlinedInput-root,
.boderRadius2 {
  border-radius: 2px !important;
}
.boderRadius4 .MuiOutlinedInput-root {
  border-radius: 4px !important;
}
.boderRadius6 .MuiOutlinedInput-root {
  border-radius: 6px !important;
  background: #f2f4f5;
}
.chipboderRadius10 .MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.bordernone fieldset {
  border-color: transparent !important;
}
.br-none fieldset {
  border: transparent !important;
}
.br-bnone fieldset {
  border-bottom: transparent !important;
}
.br-tnone fieldset {
  border-top: transparent !important;
}
.tabborder_none .MuiTabs-indicator {
  display: none;
}
.bgnone .MuiOutlinedInput-root {
  background: transparent !important;
}

.circularbarPrimary .MuiCircularProgress-colorPrimary {
  color: "#54C0FD !important"
}

/*** ScrollBar CSS  ***/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  visibility: visible;
}
::-webkit-scrollbar-track {
  background: #eeeeee;
  -webkit-border-radius: 10px;
  visibility: visible;
}
::-webkit-scrollbar-thumb {
  background: rgb(184, 182, 182);
  -webkit-border-radius: 10px;
  visibility: visible;
}
::-webkit-scrollbar-thumb:hover {
  background: #9f9f9f;
}
* {
  scrollbar-width: thin;
}
.scrolly:hover::-webkit-scrollbar,
.scrolly:hover::-webkit-scrollbar-track,
.scrolly:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.scrollx:hover::-webkit-scrollbar,
.scrollx:hover::-webkit-scrollbar-track,
.scrollx:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.sticky-table:hover::-webkit-scrollbar,
.sticky-table:hover::-webkit-scrollbar-track,
.sticky-table:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.MuiAutocomplete-listbox:hover::-webkit-scrollbar,
.MuiAutocomplete-listbox:hover::-webkit-scrollbar-track,
.MuiAutocomplete-listbox:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.MuiAutocomplete-option.Mui-focused {
  background-color: rgba(0, 0, 0, 0.04);
}
.MuiAutocomplete-option[aria-selected="true"].Mui-focused {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.MuiAutocomplete-option[aria-selected="true"] {
  background-color: rgba(0, 0, 0, 0.08) !important;
}
.multi-select-option-list li[aria-selected="true"] {
  background-color: #fff !important;
}
.multi-select-option-list li[aria-selected="true"]:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.MuiMenuItem-root:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
  /* color: #54c0fd; */
}
.MuiMenuItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08) !important;
}
.MuiMenu-paper::-webkit-scrollbar,
.MuiMenu-paper::-webkit-scrollbar-track,
.MuiMenu-paper::-webkit-scrollbar-thumb {
  visibility: visible;
}
.MuiPopover-paper::-webkit-scrollbar,
.MuiPopover-paper::-webkit-scrollbar-track,
.MuiPopover-paper::-webkit-scrollbar-thumb {
  visibility: visible;
}

/*** Input number Arrow Remove***/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/*** Create Survey Page  ***/
.surveybtn_block button {
  display: block !important;
}

/*** Table ***/
.me-surveys-list .status,
.survey-list .status {
  display: inline-block;
  padding: 3px 15px;
  border-radius: 30px;
}
.me-surveys-list .status.Closed,
.survey-list .status.Closed {
  background-color: #eeeff0;
}
.me-surveys-list .status.Open,
.survey-list .status.Open {
  background-color: #d7f1ff;
}
.me-surveys-list .status.Pending,
.survey-list .status.Pending {
  background-color: #ffe7c8;
}
.panel-active {
  color: #fff !important;
}
.panel-inactive {
  color: #c0c1c2 !important;
}
.custom-pagination {
  background-color: #fff;
  border-radius: 0px 0px 10px 10px;
  padding: 14px;
}
.tableIcon.MuiAvatar-root.MuiAvatar-colorDefault {
  background: transparent !important;
}
.tablefocusdisable.MuiAvatar-root.MuiAvatar-colorDefault:focus {
  outline: none;
}

.tablecellfocusdisable.MuiTableCell-root:focus {
  outline: none;
}

/*** PE Accordions  ***/
.pe-accordion.Mui-expanded .expandicon {
  fill: #fff;
}
.expandIcon .MuiAccordionSummary-expandIcon {
  width: 36px;
  height: 36px;
}
.pe-accordion.Mui-expanded img.panel-active,
.pe-accordion.Mui-expanded .panel-active button span img {
  filter: brightness(0) invert(1);
}
.pe_color_appbar .Mui-selected img {
  filter: brightness(0) invert(1);
}
.pe_color_appbar .Mui-selected div {
  filter: brightness(0) invert(1);
}
.pe_color_appbar .MuiButtonBase-root.Mui-disabled {
  opacity: 0.2 !important;
}
/* .peaccordion_nocolor .Mui-expanded { color: #292929 !important; background: #fff !important;  } .peaccordion_nocolor .MuiAccordionSummary-root.Mui-expanded {box-shadow: 0px 0px 4px #0000000F !important;} */

/**** Wordcloud toggle ****/
.wordtoggle.MuiToggleButton-root {
  background-color: rgba(219, 219, 219, 0.904);
}
.wordactive.MuiToggleButton-root.Mui-selected {
  background-color: rgb(84, 192, 253);
  color: #ffffff;
}

/*** PE Drawer  */
.pe_drawer .MuiDrawer-paper {
  top: 56px !important;
  min-width: 376px;
  max-width: 376px;
}

.maxWidth100 {
  max-width: 100%;
}

/*** Link Hover ***/
/* .peLink:hover .MuiTypography-root { color:#54C0FD !important }; button.peLink:hover > h6.MuiTypography-root > img { filter: invert(54%) sepia(65%) saturate(408%) hue-rotate(185deg) brightness(113%) contrast(224%) !important} */
.peLink1:hover .MuiTypography-root {
  color: #54c0fd !important;
}
button.peLink1:hover > h6.MuiTypography-root > img {
  filter: invert(54%) sepia(65%) saturate(408%) hue-rotate(185deg)
    brightness(113%) contrast(224%) !important;
}
/*** Round Text Fields ***/
.rtnoborder .MuiInputBase-root {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ltnoborder .MuiInputBase-root {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  height: 30px;
}

/*** Navigation Links Active Color ***/
.activeHeader::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 6px;
  background: #54c0fd;
  top: -10px;
  border-radius: 2px;
}

.listhover .MuiList-root .MuiListItem-button:hover {
  background: #c7c7c7 !important;
}
/* .navLink:hover::before { position: absolute; content: ""; width: 30px;  height: 4px; background: #54C0FD; top: -10px; border-radius: 15px; animation-name: navlinkanim; animation-duration: 0.5s;} */
@keyframes navlinkanim {
  0% {
    width: 100%;
  }
  100% {
    width: 30px;
  }
}

/* .navLink { contain: '';position: absolute; top: 0;left: 0;width: 100%;height: 2px;background:#54C0FD;z-index: -1;transform: scale(0);transform-origin: right  }
.navLink:hover {transform: scale(1);} */

/*** Question Panel ***/
.seachinput .MuiOutlinedInput-root {
  /* background-color: #F5F5F5;   */
  border-radius: 16px;
}
.drag-question-icon {
  position: absolute;
  top: 14px;
  left: 15px;
}
.leftPos {
  left: 360px !important;
}
.br-0 .MuiOutlinedInput-root {
  border-radius: 0px;
}
.boderRadius2 {
  border-radius: 2px;
}
.boderRadius2 .MuiOutlinedInput-root {
  border-radius: 2px;
}
.boderRadius4 .MuiOutlinedInput-root {
  border-radius: 4px;
}
.height36 .MuiOutlinedInput-root {
  height: 36px;
}
.height40 .MuiOutlinedInput-root,
.height40 {
  height: 40px;
}
.height28 .MuiOutlinedInput-root,
.height28 {
  height: 28px;
}
.height28min .MuiOutlinedInput-root {
  min-height: 28px;
}
.questionPanel .MuiOutlinedInput-root {
  overflow: hidden;
}
.inputbg .MuiInputBase-root {
  background-color: #f2f4f5;
}
.bordernone fieldset,
.MuiOutlinedInput-root:hover .bordernone fieldset {
  border-color: transparent !important;
}
.br-none fieldset,
.MuiOutlinedInput-root:hover .br-none fieldset {
  border: transparent !important;
}
.br-bnone fieldset,
.MuiOutlinedInput-root:hover .br-bnone fieldset {
  border-bottom: transparent !important;
}
.br-tnone fieldset,
.MuiOutlinedInput-root:hover .br-tnone fieldset {
  border-top: transparent !important;
}
.border-none,
.MuiOutlinedInput-root:hover .border-none {
  border: none !important;
}
.pl-0 .MuiInputBase-input {
  padding-left: 0px !important;
}
.heightone .MuiInputBase-inputAdornedEnd {
  height: 16px !important;
}
.clientDropDown .MuiInputBase-inputAdornedEnd {
  height: 28px !important;
}
.hls .MuiSelect-select {
  height: 1em !important;
  padding-left: 2px !important;
}
.hlsSelect .MuiSelect-select{
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.quest_list:hover span.txtgrey {
  color: #54c0fd !important;
}
.answer_popup .MuiPaper-root {
  width: 250px;
  overflow: auto;
  padding: 15px;
  max-height: 290px;
}
.quest_link {
  color: #54c0fd !important;
}
.quest_link:hover {
  color: #54c0fd !important;
}
.colorOrange .MuiInputBase-input {
  color: #ffa12d !important;
}
.settings_icon_active,
.imgOrange {
  filter: invert(59%) sepia(65%) saturate(431%) hue-rotate(-3deg)
    brightness(112%) contrast(225%) !important;
}
.settings_icon {
  color: #c0c1c2 !important;
}
.opacityone .MuiInputBase-input::placeholder {
  opacity: 1 !important;
}
.opacityBold .MuiInputBase-input::placeholder {
  opacity: 0.5 !important;
  font-weight: bold !important;
}
.textBoldCat .MuiInputBase-input {
  font-weight: bold !important;
}
.ques_text_grey input,
.ques_text_grey {
  color: gray !important;
}
.bgGrey .MuiOutlinedInput-root {
  background-color: #f4f6f7 !important;
}
.fontSize13 .MuiTypography-body1 {
  font-size: 13px !important;
}
.fontSize13 .MuiOutlinedInput-input {
  font-size: 13px !important;
}

.fontSize14 .MuiOutlinedInput-input {
  font-size: 14px !important;
}

/** input field height **/
.heightInput .MuiOutlinedInput-input {
  height: 47px !important;
}

/*** TimePicker ***/
.timepicker .MuiIconButton-root {
  padding: 0px !important;
}

/* Conversion Page */
.conversionAutoComplete
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 5px !important;
}
.conversionAutoComplete
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 0px !important;
  padding-left: 6px !important;
  padding-right: 20px !important;
}
.text-validator-wrapper {
  width: 100%;
}
.nounderline .MuiInput-underline:before {
  border-bottom: none !important;
}

/*** Input Validator Text Textbox 36 ***/
.height36 .MuiFormHelperText-marginDense.Mui-error {
  top: 39px;
}

/* Disabled Field Arrow Hide */
.MuiSelect-icon.Mui-disabled {
  display: none;
}

/*** Badge Big ***/
.badgebig .MuiBadge-badge {
  height: 26px;
  width: 26px;
  border-radius: 26px;
}
.badgeextrabig .MuiBadge-badge {
  height: 30px;
  width: 30px;
  border-radius: 28px;
}
.badgeMedium .MuiBadge-badge {
  height: 24px;
  width: 24px;
  border-radius: 24px;
}

/*** TextArea ***/
.pe_textarea {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 15px;
  color: #292929;
  resize: none;
  font-family: "Roboto";
}
.pe_textarea_border_none {
  border: none;
  padding: 15px;
  color: #292929;
  resize: none;
  font-family: "Roboto";
}
.fontMontserrat {
  font-family: "Montserrat" !important;
}
.fontRoboto {
  font-family: "Roboto" !important;
}
.pe_textarea:focus {
  border: 2px solid #54c0fd;
  outline: none !important;
}

.pe_textareagrey {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 15px;
  color: #292929;
  font-family: "Roboto";
}

.pe_textareagrey:focus {
  border: 1.5px solid #c2c2c2;
  outline: none !important;
}

/***  More than One line hide  ***/
.oneline_ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.oneline_ellipseWordBreak {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.oneline_ellipse_input .MuiInputBase-input {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/***  Custom Tooltips for Table and Information Icon popup***/
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  display: block;
}
[data-title]:after {
  content: attr(data-title);
  background-color: #ffffff;
  color: #292929;
  font-weight: normal;
  font-size: 13px;
  position: absolute;
  padding: 5px 10px;
  left: 10px;
  white-space: normal;
  top: 32px;
  box-shadow: 1px 1px 3px #22222233;
  opacity: 0;
  z-index: 99999;
  display: none;
  height: auto;
}
[data-title] {
  position: relative;
  height: auto;
}
.initialtitle {
  position: initial !important;
}

/* .c_tooltip .c_tooltiptext::after {content: "";position: absolute;bottom: 100%;left: 50%;margin-left: -5px;border-width: 5px;border-style: solid;border-color: transparent transparent #F2F4F5 transparent;} */
.c_tooltip:hover .c_tooltiptext {
  visibility: visible;
  z-index: 999;
  border: 1px solid #ebeced;
}

.c_tooltip {
  position: relative;
  display: inline-block;
}
.c_tooltiptext {
  visibility: hidden;
  min-width: 300px;
  background: #f2f4f5 0% 0%;
  box-shadow: 0px 0px 1px #0000001f;
  color: #292929;
  text-align: center;
  padding: 20px;
  position: absolute;
  z-index: 1;
  top: 110%; /* left: 50%; */
  right: 0;
  margin-left: -60px;
}

.multi-select-option-list li {
  padding: 0;
  background-color: #fff;
}
.multi-select-option-list li[aria-selected="true"] {
  background-color: #fff;
}
.multi-select-option-list li[aria-selected="true"]:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

/*** High Level Summary ***/
.hlsBarPositive {
  width: 0%;
  height: 25px;
  background: none repeat scroll 0% 0% rgb(84, 192, 253);
  text-align: center; /* To center it horizontally (if you want) */
  line-height: 30px; /* To center it vertically */
  color: white;
}
.hlsBarNegative {
  width: 0%;
  height: 25px;
  background: none repeat scroll 0% 0% #f79646;
  text-align: center; /* To center it horizontally (if you want) */
  line-height: 30px; /* To center it vertically */
  color: white;
}

/*** Take Survey ***/
.singleQuestionMatrix:nth-child(odd) {
  background: #f4f6f7 !important;
}

.panel-error {
  border: 1px solid red;
}

.date-picker-custom .MuiInputBase-root {
  padding-right: -14px;
}
.date-picker-custom .MuiInputAdornment-positionEnd .MuiIconButton-root .MuiIconButton-edgeEnd .MuiIconButton-sizeMedium  {
  /* padding-right: 5px; */
  opacity: 0.7 !important;
}
.date-picker-custom .MuiFormHelperText-root {
  color: red !important;
}
.date-picker-custom .MuiIconButton-root .MuiIconButton-edgeEnd .MuiIconButton-sizeMedium {
  opacity: 0.7 !important;
} 
.date-picker-custom .MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium {
  opacity: 0.7 !important;
}
.date-picker-custom .MuiButtonBase-root .MuiIconButton-root .MuiIconButton-edgeEnd .MuiIconButton-sizeMedium {
  opacity: 0.7 !important;
}
/* .date-picker-custom div fieldset {
  border-radius: 4px;
} */

/** SPcaller **/

.phone-mask-copy {
  padding: 4px;
  background-color: #f1eded;
  border-radius: 10px;
  cursor: pointer;
}

.caller_detail_datetime_entry .MuiFormHelperText-root {
  bottom: -24px;
  color: red;
  /* position: absolute; */
}

.caller_detail_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2000;
}
.caller_detail_loader .MuiCircularProgress-root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.caller-history table tr td div.txtcenter {
  text-align: left !important;
}

.language-dropdown .MuiAutocomplete-endAdornment {
  visibility: hidden;
}

.caller-queue-wrapper:focus {
  outline: none !important;
}

.posAbsDataFilterDashboardIcon {
  position: absolute !important;
  top: 80px;
  right: 64px;
}

/* @media only screen and (max-width: 1235px) {
  .posAbsDataFilterDashboardIcon {
    right: 85%;
  }
}

@media (min-width: 1508px) and (max-width: 1632px) {
  .posAbsDataFilterDashboardIcon {
    right: 48%;
  }
}

@media only screen and ( min-width: 1673px) {
  .posAbsDataFilterDashboardIcon {
    right: 19%;
  }
}

@media only screen and ( min-width: 2090px) {
  .posAbsDataFilterDashboardIcon {
    right: -45%;
  }
}

@media only screen and ( min-width: 2240px) {
  .posAbsDataFilterDashboardIcon {
    right: -115%;
  }
} */



.posAbsDataFilterInsightIcon {
  position: absolute !important;
  top: 80px;
  right: 84px;
}

.posAbsDataFilterInsightIconWithMoreButton {
  position: absolute !important;
  top: 80px;
  right: 108px;
}

/** People **/
.multiSelectInput input::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}

.multiSelectInput input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

.multiSelectInput input::placeholder {
  color: #000;
  opacity: 0.9 !important;
}

.caller-detail-popup {
  width: 100%;
  position: fixed;
  top: 0;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
}

td > div:focus {
  outline: 0;
}

.internet-error {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  margin-top: 0;
  font-size: 20px;
  z-index: 9999999;
  top: 0;
  left: 0;
}

.internet-error p {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 25px;
  line-height: 60px;
  color: #000;
  text-align: center;
  font-weight: bold;
  width: auto;
  height: 100px;
}
.MuiTypography-h3.desc {
  max-width: 98%;
}
.title-description {
  clear: both;
  display: block;
  font-weight: 100;
  font-size: 12px;
}

.MuiList-padding.no-pad-top {
  padding-top: 0px;
}
.MuiButtonBase-root.menu-header {
  background-color: #fff !important;
  color: #000;
  pointer-events: none;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 14px;
  padding-bottom: 10px;
}
.MuiListItem-gutters.menu-item-tab-space.bt-divider {
  margin-bottom: 12px;
}
.MuiButtonBase-root.menu-header.bt-divider {
  border-top: 2px solid #f2f4f5;
}
.MuiListItem-gutters.menu-item-tab-space {
  padding-left: 22px;
}
.txt-right {
  text-align: right;
}

.pe_drawer.additional_reports
  .MuiAccordionSummary-content
  .MuiTypography-h6 {
  font-weight: bold;
}
.pe_drawer.additional_reports a.cursor-pointer {
  text-decoration: underline !important;
}
.select-demographics-popper ul > li:first-child {
  color: #c0c1c2;
}
.inner_accord {
  width: 100%;
}
.inner_accord.MuiPaper-elevation1 {
  border: 1px solid #d5d6d6;
}
.inner_accord.MuiPaper-elevation1.Mui-expanded {
  border: 1px solid transparent;
}
.inner_accord .MuiIconButton-root.Mui-expanded {
  color: #fff;
}
.inner_accord .MuiAccordionSummary-root .MuiTypography-h6 {
  font-weight: bold;
}
.inner_accord .MuiAccordionSummary-root.Mui-expanded .MuiTypography-h6 {
  font-weight: bold;
  color: #fff;
}
.inner_accord .MuiAccordionDetails-root {
  background-color: #f4f6f7;
  padding-top: 16px;
}
.inner_accord div[class^="TableComponent"] {
  border-radius: 5px im !important;
}
.lefttab_height {
  height: calc(100% - 49px) !important;
}

.lang_chip.se_err,
.cat_tab.se_err {
  background-color: red;
  color: #fff !important;
}
.quest_text.se_err .MuiOutlinedInput-root {
  border: 1px solid red !important;
}
.dialogtype .MuiPaper-root {
  box-shadow: 0px 8px 15px -2px rgb(0 0 0 / 20%),
    0px 6px 4px -2px rgb(0 0 0 / 14%), 0px 6px 20px -1px rgb(0 0 0 / 12%);
}
.applytranslate {
  border-top: 1px solid #eeeeee;
}
.applytranslatebold {
  border-top: 2px solid #eeeeee;
}

.MuiFormControlLabel-root.me-select-box {
  margin-left: 12px;
}

.me-surveys-tab-container div[class^="TableComponent"] {
  border-radius: 0px 0px 10px 10px !important;
}

.cardText .MuiOutlinedInput-root.Mui-disabled input {
  color: #000;
}

.cardText .MuiOutlinedInput-root.Mui-disabled fieldset {
  border-color: transparent !important;
}

.custom-popup.no-title .MuiDialogTitle-root {
  display: none;
}
.custom-popup .MuiDialogActions-root {
  margin-top: 0px;
}
.dialog-padding .MuiDialog-root .MuiDialog-paper {
  padding: 15px !important
}
.custom-popup .MuiDialogContent-root .MuiDialogContentText-root {
  margin-bottom: 0px;
  color: #000;
}
.mr-edit-icon .Mui-disabled {
  opacity: 0.4;
}
.mrg-tb .MuiFormHelperText-marginDense.Mui-error {
  top: 20px;
}
.scroll {
    top: 12px !important;
}
.delBtn {
  top: 12px !important;
}
.pop {
  top:37px !important;
}
.oneline_ellipse.error {
  min-width: 100%;
  min-height: 24px;
  border: 1px solid red;
}

.auto-translate.non-edited .ck.ck-editor__main>.ck-editor__editable {
  background-color: #f4f6f7;
}

.auto-translate.edited .ck.ck-editor__main>.ck-editor__editable {
  background-color: #fefaf4;
}

.auto-translate.initial .ck.ck-editor__main>.ck-editor__editable {
  background-color: #ffff;
}

.no-content .ck.ck-editor__main>.ck-editor__editable {
  background-color: transparent !important;
}

.overall-left-wrapper {
  display: flex;
  /* align-items: stretch; */
  align-self: stretch;
}

.overall-left {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.overall-right-wrapper {
  padding-top: 30px;
  align-self: center;
  justify-content: center;
  flex: 1;
}

.overall-right {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: space-evenly;
}

.custom-cke-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 44px 9px 9px 9px;
  color: #ccc;
}

.ck-editor__editable_inline { 
  min-height:200px;
   max-height:400px; 
  }

.bulletinLeft ul > li  {
  text-align: left !important;
}
.orderedList ol > li  {
  text-align: left !important;
}

.competency-chart-table th,
.competency-chart-table td {
  padding: 4px;
  font-weight: 400;
  text-align: center;
}
.competency-chart-table th {
  padding-top: 0px;
}
.competency-chart-table th h4 {
  font-weight: bold;
}
.competency-chart-table.relationship-table {
  border-collapse: collapse;
  /* float: revert;
  width: auto;
  position: relative;
  right: 400px; */
} 
.competency-chart-table.relationship-table th {
  height: 34px;
}
.competency-chart-table.relationship-table th,
.competency-chart-table.relationship-table td{
  padding: 4px 0px;
}
.competency-chart-table.relationship-table tr td:first-child {
  border-right: 1px solid #ccc;
  text-align: right;
  padding-right: 8px;
}

.alignRight {
  text-align: right;
}

.bar-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ececec;
  z-index: 0
}

.bar-original {
  position: absolute;
  height: 100%;
  background-color:rgb(84, 192, 253);
  z-index: 0
}

.MuiStepButton-vertical.disabled {
  opacity: 0.5;
}
.competency-popup .MuiDialog-paperScrollPaper {
  display: block;
}
.item_group_heading {
  background-color: #f2f4f5;
  display: block;
  width: 100%;
  padding: 4px;
}
.Plusicon button{
  padding : 0px;
}
.CompetencySummaryChart-scrollc-680:first-child{
  padding-left: 0px !important;
}

.custom-form-input .MuiOutlinedInput-root.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  /* padding-top: 0px;
  padding-bottom: 0px; */
}

.divWidth > div {
  width: 96%;
}
.disableBorder fieldset:first-of-type {
  border: 0;
}
.MuiFormHelperText-root.Mui-error {
  color: #f44336;
}
.css-1hot9p5-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336;
}
.callerDetailSelectDateAndTime > div {
  max-width: 145px;
  margin-right: 10px;
}
.callerDetailSelectDateAndTime div input {
  padding-right: 0;
}
/* uncomment to bring error border color */
/* .errorBorder div > fieldset {
  border-color: #f44336;
} */
.pulseTextBox > p {
  position: absolute;
  top: 28px;
}
.rem875 {
  font-size: 0.875rem;
}

/*Absolute right*/
.absolutert {
  position: absolute;
  right: 1px;
}

.w218 {
  width: 218px;
}
.w456{
  width: 856px ;
}


.custom-time-picker .MuiPickersArrowSwitcher-root{
  top: 105px;
  justify-content: center;
  display: none;
}

.ht-adj.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 8px 4px 8px 6px;
  height: 28px
}

.placeholderAlign input {
  position: absolute;
  width: auto !important;
}

.MuiSnackbarContent-message {
  font-weight: 700;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px;
}
.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 8px 4px 8px 8px;
}

.MuiButtonBase-root.MuiPickersDay-today {
  border: none;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.pad14 .MuiInputBase-input.MuiOutlinedInput-input{
  padding: 14px;
}

#setup_demographic .MuiInputBase-input.MuiOutlinedInput-input:not(.MuiSelect-select) {
  font-size: 14px;
}
/* 
.MuiCheckbox-root svg {
  background: #54c0fd;
} */

.backBtn {
  margin-top: 7.5px;
}

.removeTopMargin div {
  margin-top: 0px;
}

.hlstable {
  /* max-width: 100%; */
}
.hlstable th,
.hlstable td {
    width: auto !important;
    min-width: auto !important;
    /* border:1px solid #f1f1f1 */
}
.hlstable th:first-child,
.hlstable td:first-child {
  text-align: left;
  max-width: auto;
}
.hlstable th {
  text-align: center;
  padding-left: 4px !important;
  vertical-align: middle;
  min-width: 80px !important;
  padding-bottom: 15px !important;
}
.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hlstable th .MuiTableSortLabel-icon {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
}

.hlstable th:first-child .MuiTableSortLabel-icon {
  position: relative;
}

.displayInline {
  display: inline;
}

.displayGrid {
  display: grid;
}

.placeItemCenter {
  place-items: center
}

.dflex {
  white-space: unset;
}

@media (max-width: 1200px) {
  .heightUnsetOnMobile {
    height: unset!important;
  }
}
.dNone {
  display: none;
}

/* Dropdown question css*/
 .dropdown-border tbody td{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.8 !important;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  /* border: 2px solid #F2F4F5;  */
  text-align: left;
  padding: 16px;
  color: rgba(0, 0, 0, 0.87);
  border-bottom: 2px solid #F2F4F5 !important; 
  padding: 0px 4px;
  font-size: 13px;
} 
/* .tablescroll table {
  scrollbar-width: auto !important;
} */
 
.dropdown-border thead{
  height: 40px !important;
  background-color:#f2f4f5 !important;
}
.dropdown-border tbody tr {
  height: 38px !important;
}
.dropdownWidth{
  width: 150px;
  border-radius: 5px;
}
.dropdown-border .clearLeft{
  clear: left;
}
.dropdown-border .floatLeft{
  float: left;
}


/* dropdown view all button css*/
.view-btn-div {
  /* width: 200px;
  height: 100px; */
  font-family: "Montserrat" !important;
  font-weight: 600;
  font-size: 13px !important;
  color: #54C0FD !important;
  text-align: center;
  text-decoration: underline;
  padding-top: 12px;
 
  /* line-height: 100px; 
  transition: background-color 0.3s ease; */
}

.view-btn-div:hover {
  cursor: pointer; /* Change cursor on hover */
  
}

.arrow {
  margin-left: 2px;
  font-size: 10px;
  font-weight: 200 !important;
}

.image-container {
  display: grid;
  place-items: center;  /* Centers the image both horizontally and vertically */
  width: 200px;
  height: 135px;
  background-color:#f2f4f5 !important;
  border-radius: 10px;
  /* margin-left: 12px; */
  /* margin-bottom: 12px; */
  padding: 10px 10px 0 10px !important;
  /* padding-left: 10px; */
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
}

.image-text {
  margin-top: 5px;  /* Space between the image and text */
  font-size: 13px;   /* Optional: Customize text size */
  color: #333;       /* Optional: Customize text color */
}

.image-container2 {
  display: grid;
  place-items: center;  /* Centers the image both horizontally and vertically */
  width: 180px;
  height: 110px;
  background-color:#f2f4f5 !important;
  border-radius: 10px;
  padding-top: 8px;
}

.image-container2 img {
  max-width: 100%;
  max-height: 100%;
}

.image-text2 {
  margin-top: 5px;  /* Space between the image and text */
  font-size: 13px;   /* Optional: Customize text size */
  color: #333;       /* Optional: Customize text color */
}
