.queryBuilder {
    display: block;
    float: left;
    width: 100%;
    min-height: 200px;
    padding: 15px;
    background-color: #F7F9FA;
    margin-bottom: 15px;
}
.queryBuilder-wrapper {
    width: 100%;
    min-height: 150px;
    margin-top: 10px;
}
.queryBuilder .ruleGroup  {
    float: left;
    width: 100%;
    text-align: right;
    position: relative;
}
.combinator-selector-btngrp {
    float: left;
}
.combinator-selector-btngrp button {
    margin-right: 0px !important;
    border-radius: 0px;
    padding: 0px;
    border-color: #C0C1C2;
    background-color: #F7F9FA;
    color: rgba(0, 0, 0, 0.87);
}
.combinator-selector-btngrp button:hover {
    background-color: #ccc;
    border-color: #C0C1C2;
}
.combinator-selector-btngrp button.active {
    background-color: #FFA12D!important;
    color: #fff!important;
}
.queryBuilder .ruleGroup .ruleGroup-header {
    width: 100%;
    margin-bottom: 8px;
    position: relative;
    z-index: 2;
}
.queryBuilder .ruleGroup .ruleGroup-header button{
    margin-right: 8px;
}
.queryBuilder .ruleGroup .ruleGroup-header button span {
    padding: 0px;
}
.queryBuilder .ruleGroup .rule {
    margin: 12px 0px;
    padding: 11px 8px;
    background-color: #fff;
    text-align: left;
    display: flex;
    /* justify-content: space-between; */
    margin-left: 20px;
    position: relative;
    box-shadow: 0px 0px 4px #0000000F;
}
.queryBuilder .ruleGroup .rule::before {
    content: " ";
    position: absolute;
    width: 14px;
    height: 1px;
    background-color: #ccc;
    left: -14px;
    top: 50%;
}
.querybuilderhelptext {
    position: relative;
    padding: 5px 10px 5px 5px;
    top: 10px;
    left: 50%;
    width: 53%;
    transform: translate(-58%, 0);
    text-align: center;
    font-size: 13px ;
    border-radius: 10px 10px;
    background-color: #EDF9FF;
    border-style: dashed;
    border-color: #74d3ff;
    border-width: .5px;
}
.queryBuilder .ruleGroup .ruleGroup-header .ruleGroup-combinators {
    float: left;
}
.queryBuilder .ruleGroup .rule .p5.rule-del-icon {
    align-self: center;
    margin-left: auto;
}

.queryBuilder .ruleGroup .rule .input-value-editor {
    border: 1px solid #ccc;
    width: 20%;
    border-radius: 15px;
}
.queryBuilder .ruleGroup .rule .input-value-editor.text-validator,
.queryBuilder .ruleGroup .rule .input-value-editor.autocomplete.text-validator {
    width: 100%
}
.queryBuilder .ruleGroup .rule .error .input-value-editor,
.queryBuilder .ruleGroup .rule .input-value-editor.error {
    border-color: red;
}
.queryBuilder .ruleGroup .rule .text-validator-wrapper {
    display: inline-flex;
    align-self: center;
    padding: 0px;
    position: relative;
    margin: 0px;
    width: 100%;
}
.query_and {
    border-radius: 4px 0px 0px 4px !important;
    width: 44px;
}
.query_or{
    border-radius: 0px 4px 4px 0px !important;
    margin-right: 2px;
}
.queryBuilder .ruleGroup .rule .text-validator-wrapper .MuiFormHelperText-root {
    position: absolute;
    top: 100%;
    right: 0px;
    color: red;
    margin: 0px;
    font-size: 0.75rem;
}
.queryBuilder .ruleGroup .rule .input-value-editor::before,
.queryBuilder .ruleGroup .rule .input-value-editor::after,
.queryBuilder .ruleGroup .rule .input-value-editor.autocomplete .MuiInputBase-root::before,
.queryBuilder .ruleGroup .rule .input-value-editor.autocomplete .MuiInputBase-root::after {
    display: none;
}
.queryBuilder .ruleGroup .rule .input-value-editor input {
    background-color: transparent;
}

/* nested group style */
.ruleGroup .ruleGroup {
    padding-left: 20px;
    position:relative;
    z-index: 1;
}

.ruleGroup .ruleGroup::before { 
    content: " ";
    position: absolute;
    width: 14px;
    height: 1px;
    background-color: #ccc;
    left: 6px;
    top: 12px;
}
.helpicon {
    float: left;
    padding: 12px;
}

.ruleGroup .ruleGroup::after {
    content: " ";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #ccc;
    left: 6px;
    top: -15px;
}

/* inpujt styles height */
.queryBuilder .ruleGroup .rule .MuiInputBase-root {
    line-height: 1.5rem;
    align-self: center;
    border-radius: 30px;
}   
/* for date picker */
.queryBuilder .ruleGroup .rule .date-picker {
    line-height: 1.1876em;
    border: 0px solid transparent;
    margin-top: 0px;
    margin-bottom: 0px;
}
.queryBuilder .ruleGroup .rule .date-picker .MuiInputBase-root {
    line-height: 1.1876em;
}
/* for auto complete */
.queryBuilder .ruleGroup .rule .input-value-editor.autocomplete {
    width: 20%;
    border-radius: 15px;
    align-self: center;
    border: 0px solid #ccc;
}
.queryBuilder .ruleGroup .rule .input-value-editor.autocomplete .MuiFormControl-root {
    margin: 0px;
}
.queryBuilder .ruleGroup .rule .input-value-editor.autocomplete .MuiFormControl-root input {
    padding-left: 8px;
    height: 16px;
}

.queryBuilder .ruleGroup .rule-wrapper {
    position: relative;
}

.queryBuilder .ruleGroup .rule-wrapper:before {
    content: " ";
    position: absolute;
    width: 1px;
    height: 20px;
    background-color: #ccc;
    top: -20px;
    left: 6px;
}

.queryBuilder .ruleGroup > .rule-wrapper ~ .rule-wrapper:before {
    content: " ";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #ccc;
    top: -12px;
    left: 6px;
}

.queryBuilder .ruleGroup .rule-wrapper .rule:after {
    content: " ";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color:#ccc;
    left: -14px;
    top: 0px;
}
/* to hide last rule extra line */
.rule-wrapper:last-child:after {
    content: " ";
    position: absolute;
    width: 1px;
    height: 36px;
    left: 6px;
    bottom: -17%;
    background-color: #F7F9FA;
}
.query-builder-option-wrapper {
    padding: 0px !important;
}
.MuiAutocomplete-option.query-builder-autocomplete-option {
    padding: 6px;
}
.MuiAutocomplete-option.query-builder-autocomplete-option:hover {
    background-color: #F2F4F5 !important;
}
.MuiAutocomplete-option.query-builder-autocomplete-option[aria-selected="true"]{
    background-color: transparent;
}
.MuiButtonGroup-grouped:not(:first-of-type) {
    color: rgba(0, 0, 0, 0.87);
}

.query-builder-autocomplete-option .autocomplete-checkbox {
    padding: 1px !important;
    margin-right: 4px;
}

.query-builder-autocomplete-option .autocomplete-checkbox .MuiSvgIcon-colorPrimary {
    color: #54C0FD;
}

.rule > div {
    margin-right: 20px !important;
    width: 20%;
}

.queryBuilder .ruleGroup .rule .MuiSelect-select {
    height: 22px;
    padding: 2px 8px;
}