@media only screen and (max-width: 992px) {
  .servey_tile {
    max-width: 340px;
  }
  /* .createsurv_left
    {
      height: auto !important;
    } */
}
@media only screen and (min-width: 992px) {
  .servey_tile {
    width: 320px !important;
  }
  .people_tile {
    max-width: 318px;
  }
  .settings_dlticon {
    text-align: right !important;
    padding: 15px !important;
  }
}
@media only screen and (min-width: 1200px) {
  .lg-pr-80 {
    padding-right: 80px !important;
  }
  .lg-pl-30 {
    padding-left: 30px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .banner_text {
    font-size: 30px !important;
  }
  .createsurvey_div {
    background: #f2f4f5;
    padding: 20px 0;
  }
}
@media only screen and (max-width: 1070px) {
  .createsurvey_div,
  .createsurv_div {
    height: auto !important;
  }
}

@media only screen and (max-width: 768px) {
  /* .stepper_btns{
        position:relative !important;
        text-align: center;
      } */
  .stepper_div {
    margin: initial !important;
  }
  .scrollContainer {
    height: 100% !important;
  }
}

@media only screen and (min-width: 600px) {
  .sm-30 {
    flex-grow: 0 !important;
    max-width: 30% !important;
    flex-basis: 30% !important ;
  }
}
@media only screen and (max-width: 525px) {
  .stepper_btns {
    position: relative !important;
    text-align: center;
  }
}
@media only screen and (max-width: 960px) {
  .question_rtbar,
  .question_ltbar {
    height: auto !important;
  }
  .question_ltbarins {
    margin-right: 0px !important;
  }
}

/* Take Survey */
@media only screen and (max-width: 510px) {
  .answerSettxt {
    font-size: 10px !important;
  }
  .answerSet {
    word-break: break-word;
    min-width: 57px;
  }
}

@media only screen and (max-width: 599.95px) {
  .MuiTabs-scrollButtonsDesktop {
    display: inline-flex !important;
  }
  .takesurvey_appbar .MuiTab-root {
    width: -webkit-fill-available !important;
    max-width: -webkit-fill-available !important;
  }
  .takesurvey_appbar .MuiTabs-scrollButtons {
    visibility: hidden !important;
  }
  .takeSureveyButtomAction {
    display: none !important;
  }

  .mobileButtons {
    display: inline-flex !important;
    width: 40px !important;
    padding: 12px !important;
    position: absolute !important;
    z-index: 1 !important;
    background: #fff !important;
  }
  .buttonLeft {
    left: 25px;
  }
  .buttonRight {
    right: 25px;
  }
  .desktopShow {
    display: none;
  }
  .mobileShow {
    display: block !important;
  }
  .scrollContainer {
    padding: 10px !important;
  }
}

@media only screen and (min-width: 599.95px) {
  .takeSureveyButtomAction,
  .mobileButtons {
    display: none !important;
  }
  .desktopShow {
    display: block;
  }
  .mobileShow {
    display: none !important;
  }
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {
  .createsurv_div {
    max-width: 1620px !important;
  }
}
